body.simplelogin {
  background-position: center center;
  background-size: cover;
  min-height: 100vh;

  .login-logo {
    display: block;

    img {
      margin-top: 60px;
      max-width: 75%;
    }
  }

  .logo {
    margin: 30px 0;

    img {
      max-width: 200px;
    }
  }

  .form-item {
    input {
      background: rgba(#fff, 1);
      border-radius: 15px !important;
      color: $colour3;
      font-weight: 600;

      &::placeholder {
        color: #666 !important;
        font-weight: 600;
      }
    }

    .control-label {
      color: #fff;
      text-shadow: 0 0 15px #333;
    }
  }

  input[type="text"],
  input[type="password"] {
    padding: 10px !important;
    text-align: center;
    text-indent: 0;
  }

  .loginregis .form-item-mail input {
    background-image: none !important;
  }

  .form-submit {
    padding: 10px 20px;
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: $body-font;
    transition: 0.3s ease all;
    box-shadow: 0 0 15px #666;
    font-weight: 600;

    &:hover {
      background: $colour3;
      text-decoration: none;
    }
  }

  .signup {
    @extend .btn;
    @extend .form-submit;
  }
}

.page-user-reset {

  .loginregis input[type="text"],
  .loginregis input[type="password"] {
    width: 100%;
    color: #666;
    text-indent: 0;
    background-color: white !important;

    &::placeholder {
      color: #666 !important;
      font-weight: 600;
    }
  }

  label.control-label {
    display: none;

  }


  .form-type-password-confirm {
    .form-group {
      width: 100%;
    }
  }

  .help-block.password-help {
    position: static;
    width: 100%;
    text-align: left;
    margin: 0;
  }

  .form-control {
    color: #666;
  }

  input {
    background: rgba(#fff, 1);
    border-radius: 15px !important;
    color: $colour3;
    font-weight: 600;

    &::placeholder {
      color: #666 !important;
      font-weight: 600;
    }
  }

  .glyphicon-remove, .glyphicon-success, .glyphicon-warning-sign, .glyphicon-ok{
    font-size: 2rem;
  }
  .help-block{
    padding:20px;
    background-color: rgba(220,220,220, 0.9);
    color: $colour3;
    font-size: 18px;
    border-radius: 15px;
    margin-left: 15px !important;
  }
}
