/*============================================================== 
 sidebar
 ============================================================== */

.left-sidebar {
    position: absolute;
    height: 100%;
    top: $topbar-height;
    width: $sidebar-width-full;
    z-index: 99;
    transition: 0.2s ease-in;
    .sidenav {
        width: $sidebar-width-full;
        height: 100%;
        position: relative;
        transform: translateX(0);
        @extend .blue,
        .accent-4;
        padding-bottom: $topbar-height;
        transition: width .3s;
        ul li a i {
            text-align: center;
        }
        ul > li {
            &.small-cap {
                font-size: 12px;
                color: $white-op;
                margin: 20px 0 0px 20px;
                height: 28px;
                display: flex;
                align-items: center;
            }
            a {
                color: $sidebar-text;
                font-weight: $font-light;
                overflow: hidden;
                white-space: nowrap;
                margin: 0 11px;
                padding: 0 12px;
                &::after {
                    border-color: $gray-500;
                }
                >i.material-icons {
                    color: $sidebar-icons;
                    margin-right: 10px;
                }
                &:hover {
                    background-color: transparent;
                    color:white;
                    i{
                        color:white;
                    }
                }
            }
            &.active {
                background-color: transparent;
                > .collapsible-header {
                    @include horizontal-gradient(#11d7e4, #48eaa1);
                    border-radius: 60px;
                    i,
                    .hide-menu {
                        color: $white;
                    }
                    &::after {
                        border-color: $white;
                    }
                }
            }
        }
        .collapsible-body {
            >ul:not(.collapsible) {
                >li {
                    a {
                        color: $sidebar-text !important;
                        margin: 0 15px;
                        padding: 0 10px;
                        transition: 0.2s ease-in;
                        i {
                            text-align: center;
                            color: $sidebar-icons !important;
                        }
                    }
                    &.active,
                    &:hover {
                        background-color: transparent;
                        a {
                            color: $white !important;
                            i {
                                text-align: center;
                                font-weight: $font-medium;
                                color: $white !important;
                            }
                        }
                    }
                }
            }
        }
    }
}



.collapsible .has-arrow {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $sidebar-text;
        right: 1em;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 47%;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }
}

.collapsible .active>.has-arrow::after,
.collapsible li>.has-arrow.active::after,
.collapsible .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 45%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}

.user-profile {
    padding: 50px 15px 15px;
    line-height: 22px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    .user-name {
        vertical-align: middle;
        cursor: pointer;
        width: 100%;
        overflow: hidden;
        .name {
            font-weight: $font-normal;
            margin: 0;
            display: flex;
            align-items: center;
            i {
                margin-right: 10px;
            }
        }
    }
    #dropdownuser li {
        a,
        i {
            color: $body-text;
        }
        a {
            padding: 0 17px;
            i {
                margin-right: 5px;
            }
        }
    }
}