@media(max-width:767px) {
    .product-widget {
        .product {
            text-align: center;
            .product-detail,
            .product-action {
                margin-top: 20px;
            }
        }
    }
}