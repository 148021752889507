#loading {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.job-toggle {
    text-align: center;
    display: block;
    width: 25%;
    float: left;

    .icon {
        font-size: 1em;
    }
}

.job-status-toggle {
    display: none;
}

.job-toggle label {
    width: 100%;
    padding: 5px 10px;
    background: #2C3E50;
    color: #777;
    transition: 0.2s ease all;
    font-size: 0.9em;
    margin-bottom: 5px;
    margin: 0;
}

.job-status-toggle:checked ~ label {
    color: #fff;
}

#job-list-container {
    @extend .card;
    width: 100%;
    margin: 0;
    background: none;
    max-height: 80vh;
    overflow: hidden;

    .tabs {
       margin: 10px 0;

       .tab a.active, .tab a:hover {
           color: #2C3E50
       }

       .indicator {
           background-color: #2C3E50;
       }
    }
}

.job-modal {
    top: 50% !important;
    transform: translateY(-50%) !important;
    bottom: auto;

    .modal-content {
        border-radius: 0;
        box-shadow: none;
        border: 0;
    }

    .confirm-btn {
        background: #43a047;
        color: #fff;
    }

    .map-btn {
        background: #777;
        color: #fff;
    }
}

#external-events {
    max-height: calc(100vh - 330px);
    overflow-y: scroll;
    padding: 5px;
}

#external-events .fc-event {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1em;
    cursor: move;
    border: 0;
    background: #fff;
    color: $body-colour;
    border: 2px dashed transparent;

    .company-logo {
        max-width: 30%;
        float: right;
        margin-left: 15px;

        img {
            max-width: 100%;
            max-height: 25px;
        }
    }

    .brief {
        max-height: 100px;
        position: relative;
        padding-bottom: 15px;
        overflow: hidden;
        &:after {
            display: block;
            content: '';
            height: 25px;
            bottom: 0;
            left: 0;
            width: 100%;
            position: absolute;
            background: linear-gradient(to top, #fff 0%, transparent 100%);
        }
    }

    &.active {
        border-color: rgba(#666,0.6);
        transform: translateY(-5px);
        box-shadow: #cdcdcd 0 5px 5px;

        .brief {
            max-height: none;

            &:after {
                display: none;
            }
        }
    }

    h5 {
        margin-bottom: 5px;
    }

    .job-types {
        display: inline-block;
        .job-type {
            background: #ddd;
            color: #333;
            display: inline-block;
            border-radius: 5px;
            padding: 3px 8px;
            font-size: 0.6em;
        }
    }

    .contact-details {
        border-top: 1px solid #dedede;
        margin-top: 10px;
        padding-top: 10px;
        font-size: 0.9em;

        i {
            color: #999;
            margin-right: 10px;
        }
    }

    &.amber {
        color: #222;
    }

    .job-number {
        float: right;
        background: #333;
        font-size: 0.8em;
        display: block;
        border-radius: 5px;
        padding: 3px 10px;
        color: #fff;
        margin-left: 5px;
        margin-bottom: 5px;
        font-weight: 800;
    }
}

#calendar-container {
    height: 80vh;
    margin-bottom: 15px;
}

#calendar {
    width: 100%;
}

#map {
    @extend .card;
    height: 80vh;
    width: 100%;
}

.fc-view-container {
    background: #fff;
    @extend .card;
}

.fc-resource-area .fc-cell-text {
    font-size: 1.1em;
    font-weight: 800;
}

#job-popup {
    min-width: 200px;

    .map-drag {
        background: #333;
        color: #fff;
        float: right;
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: 15px;
    }

    p {
        margin: 5px 0;
    }

    h4 {
        margin-bottom: 0;
    }
}

label {
    color: $body-colour;
    font-size: 1rem;
    margin-bottom: 0;
}

.modal-value {
    display: block;
    margin-bottom: 15px;
}
