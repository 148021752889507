#add-company-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3;
  padding: 200px;
  display: none;
  color: white;
  // align-items: center;
  text-align: center;
  overflow-y: scroll;

  div {
    max-width: 400px;
    display: inline-block;
  }
}

#add-company-close {
  float: right;
  top: 0px;
  color: white;
  font-weight: bolder;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

}
