.page-addjobsheet {
	#section-assign-staff, #section-job-status {
		display: none;
	}
}

.form-autocomplete {
	.input-group-addon {
		display: none;
	}
}

.resizable-textarea .grippie {
	display: none;
}

.page-job-sheets-with-search {
	#DataTables_Table_0_wrapper {
		.col-sm-6 {
			width: 100%;
		}

		#DataTables_Table_0_length {
			display: none;
		}

		.dataTables_filter {
			text-align: left;
			margin-left: -15px;
		}
	}
}

.form-item-field-product-und {
	.form-type-checkbox {
		clear: right;
	}

	.custom-price, .quantity {
		float: right;

		label {
			font-size: 0.7em;
			margin-right: 5px;
			font-weight: 600;
		}
	}
}

#edit-field-product-und.form-checkboxes {
	.form-item {
		// border-bottom: 1px solid #999;
	}
}	

.file-widget {
	.btn.form-submit {
		font-size: initial;
	}
}