/*******************
Padding margin property 
*******************/

$vertical-property-map: ( m-t-: margin-top, m-b-: margin-bottom, m-r-: margin-left, m-l-: margin-right, p-t-: padding-top, p-b-: padding-bottom, p-r-: padding-left, p-l-: padding-right, p-: padding, m-:margin);
$sizes-list: 5 10 15 20 25 30 40;
@each $size in $sizes-list {
    $val: $size + 0px;
    @each $keyword,
    $property in $vertical-property-map {
        .#{$keyword}#{$size} {
            #{$property}: $val;
        }
    }
}



/** * Zero */

$zero-property-map: ( m-0: margin, m-t: margin-top, m-r: margin-left, m-b: margin-bottom, m-l: margin-right, p-0: padding, p-t: padding-top, p-r: padding-left, p-b: padding-bottom, p-l: padding-right);
@each $keyword,
$property in $zero-property-map {
    .#{$keyword}-0 {
        #{$property}: 0;
    }
}