.job-data {
  vertical-align: top;

  .cell-content {
    display: none;
  }
}

.report-icon {
  line-height: 0px;
  border-radius: 6px;
  height: 36px;
  width: 36px;

  .material-icons {
    margin-top: 18px;
    margin-left: 3px;
    font-size: 30px;
    color: #000;
  }
}

.report-legend {
  div {
    vertical-align: middle;

    .report-icon {
      display: inline-block;
    }

    p {
      padding-left: 10px;
      display: inline-block;
    }
  }
}

.dataTables_paginate {
  float: right;

  .paginate_button {
    font-size: 1rem;
    line-height: 2.25rem;
    font-weight: 500;
    letter-spacing: .0892857143em;
    text-decoration: none;
    text-transform: uppercase;
    padding: 4px 8px 4px 8px;
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 64px;
    border: none;
    outline: none;
    line-height: inherit;
    user-select: none;
    overflow: visible;
    vertical-align: middle;
    border-radius: 4px;

  }

  .current {
    background: $colour1;
    color: white;
  }
}

.dataTables_length {
  float: right;
}
