.btn {
  height: auto;
  background: $colour1;

  &.btn-small {
    font-size: 0.9em;
    padding: 6px;
    line-height: 0.9em;
  }
}

select.form-control {
  display: none;
}

.field-group-div {
  @extend .card;
  background: #fff;
  padding: 15px;
}

.control-label {
  color: #555;
  font-size: 1em;
}

// Table Styles

.table-responsive table td {
  white-space: normal;
}

.table-responsive {

  td,
  th {
    border-radius: 0;
  }
}

.views-field-nothing a,
.views-field-nothing-1 a {
  padding: 5px;
  line-height: 1em;
  font-size: 0.8em;
}

.table-striped>tbody>tr {
  border-bottom: 2px solid rgba(90, 90, 90, .1);

  &:nth-of-type(odd) {
    background: rgba(190, 190, 190, .1);
  }
}

.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th {
  background: $colour1;
}

.material-icons.status {
  color: #ccc;
  cursor: pointer;

  &.current {
    color: #000;
  }
}

textarea.form-control {
  background: none;
  min-height: 120px;
  height: auto;
  @extend .materialize-textarea;
}

fieldset.form-wrapper {
  overflow: unset;
}

.table-responsive {
  overflow: unset;
}

input.form-file {
  border: 0;
  box-shadow: none;
  padding-left: 0;
}

.field-multiple-table {
  thead>tr>th {
    background: none;

    label {
      color: #5b616e;
      font-size: 1.3em;
    }
  }

  tr,
  th,
  td {
    border: none !important;
  }
}

.field-type-file {
  .help-block {
    display: none;
  }
}

.field-add-more-submit {
  float: right;
  font-size: 0.7em;
  line-height: 1em;
  padding: 5px 15px;
}

.tabledrag-toggle-weight {
  display: none;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background: none;
}

td.field-multiple-drag {
  display: none;
}

body.page-user {
  .document-group {
    border: 1px solid #dedede;
    margin-bottom: 15px;
    padding: 15px;
  }
}

.btn {
  i {
    vertical-align: middle;
  }
}
