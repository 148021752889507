@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,900|IBM+Plex+Sans:400,500,600,700&display=swap');

$body-font: 'IBM Plex Sans', sans-serif;
$headings-font: 'Nunito Sans', sans-serif;
$panoptic-font: 'Montserrat', sans-serif;

h1, h2, h3, h4, h5, h6 {
	font-family: $headings-font;
}

strong {
	font-weight: 800;
}

// 	span.trail {
// 		color: $colour1;
// 	}

// 	&.underline {
// 		position: relative;
// 		margin-bottom: 1.5em;

// 		&:after {
// 			position: absolute;
// 			display: block;
// 			border-bottom: 5px solid $colour1;
// 			bottom: -15px;
// 			width: 30%;
// 			content:'';
// 		}
// 	}
// }

// p {
// 	font-weight: 400;
// 	font-size: 1.15em;
// 	line-height: 1.6em;
// 	margin-bottom: 1em;
// }

// .page-header {
// 	border-bottom: 2px solid $colour1;
// }

// .bg-colour1 {
// 	h1, h2, h3, h4, h5, h6 {

// 		&.underline {
// 			&:after {
// 				border-bottom: 5px solid #fff;
// 			}
// 		}
// 	}
// }

// a {
// 	color: $colour1;
// }

// .testimonial {
// 	text-align: center;
// 	padding: 30px;
// 	margin-bottom: 30px;
// 	height: 100%;

// 	.client-logo {
// 		max-width: 200px;
// 		display: block;
// 		margin: 30px auto;
// 	}

// 	blockquote {
// 		display: block;
// 		margin: 0 auto;
// 		max-width: 75%;
// 		font-family: $headings-font;
// 		font-weight: 600;
// 		font-size: 1.4em;

// 		cite {
// 			margin: 15px 0;
// 			display: block;
// 			font-weight: 400;
// 			font-style: normal;
// 			span {
// 				color: $colour1;
// 			}
// 		}
// 	}
// }

// address {
// 	span {
// 		display: block;
// 	}
// }

// .modal-testimonial {
// 	text-align: center;

// 	.modal-dialog {
// 		max-width: 90%;
// 	}

// 	.modal-content {
// 		background: #eee;
// 		color: #333;
// 	}

// 	.client-logo {
// 		max-width: 200px;
// 		display: block;
// 		margin: 30px auto;
// 	}

// 	blockquote {
// 		display: block;
// 		margin: 0 auto;
// 		max-width: 75%;
// 		font-family: $headings-font;
// 		font-weight: 600;
// 		font-size: 1.2em;

// 		cite {
// 			margin: 15px 0;
// 			display: block;
// 			font-weight: 400;
// 			font-style: normal;
// 			span {
// 				color: $colour1;
// 			}
// 		}
// 	}

// }