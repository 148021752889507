$colour1:	          #1b84e7;
$colour2:	          #052fac;
$colour3:           #001737;
$colour4:           #5caa59;
$colour5:           #dc3545;
$colour6:           #6c757d;
$colour7:           #ced4da;
$colour8:           #63a3fe;
$colour9:           #042893;

$skin1:             #273146;
$skin2:             #fe5419;
$skin3:             #00b0ff;
$skin4:             #6659f7;
$skin5:             #273146;
$skin6:             #fff;


$body-bg:           #fff;
$body-colour:       #001737;

$primary:	          $colour1;

$menu_width: 400px;

$wi-path: "../fonts/";
$slick-font-path:   "../fonts/";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1570px
);
