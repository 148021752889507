.model-stats{
    position:absolute;

    top:0px;
    left:12px;
    color:#FFF;
    padding: 30px;
    height: 100%;
    width: 100%;
    background-color: rgba(40,40,40,0.7);
    display:none;
    align-content: center;

}

.model-stats-show{
    display:block;

}
.grid-item{
    // width:400px;
    padding-right:0px;
    padding-left: 0px;
    padding:1px;
}

.model-gallery-item{
    // min-height: 100%;

    .image-gallery-tick-overlay{
        position: absolute;
        top: 0px;
        right:0px;
        // padding-right: 15px;
        font-size: 6rem;
        color:#49b649;
        display:none;
        z-index:99;

    }

    .image-gallery-tick-overlay-show{
        display:block;
    }

    .model{
        padding:10px;
        position:absolute;
        left:12px;
        top:0px;
        width:100%;
        height:100%;
        display: block;
        // visibility: hidden;
    }
    .placeholder{
        height: 100%;
        visibility:hidden;
        // padding-bottom:30px;
        img{
            height: 100%;
        }
    }
    .grid-sizer{
        width:100%;
    }


}
.model-stats-table{
    // color:#ff0000;
    tbody{
        tr{
            td{
                vertical-align: top;
                align-content: left;
                padding:5px;
            }
            td:first-of-type{
                font-weight:bold;
                font-size:14px;

            }

        }
    }

}

.profile-stats{
    padding-left:0px;
    padding-right:0px;
    padding:0px;

}
.grid{
    padding-left:0px;
    padding-right:0px;
}
