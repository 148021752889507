#support-list-container {
  .card {
    padding: 15px;
    border-top-left-radius: 15px;
    //  border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;


  }

  .active {
    box-shadow: #cdcdcd 0 5px 5px;
    transform: translateY(-5px);
  }

  .status-select {
    border-bottom: 5px solid rgb(44, 62, 80);
  }

}
