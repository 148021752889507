.report-content {
  background-color: #000;
  padding: 0px 20px 0px 20px;

  .top-frame {
    height: 150px;
    padding: 10px;
    text-align: center;
    color:#FFF;
    h1{
      color: #fff;
    }
    p{
      text-align: right;
    }
    img{
      max-height: 140px;
      max-width:200px;
      filter: brightness(0) invert(1);
      float: left;
    }
  }

  .bottom-frame {
    height: 50px;
    text-align: right;
    color: #FFF;
    font-weight: 100;
    div {
      vertical-align: middle;
      display: inline-block;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.2em;
      font-family: Montserrat, sans-serif;
      span{
        font-weight: 400;
      }
      img {
        max-height: 40px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}
.report-popup-content {
  border-radius: 20px !important;
  padding: 0px 0px 0px 0px !important;
}

.report-close-action{
  color: #eb1940 !important;
  background-color: #000 !important;
  outline: 0 !important;
  box-shadow: none !important;
  &:hover{
    border: 1px solid #eb1940 !important;

  }
  &::selection{
    background: none;
  }
}


