.scroll-container{
  min-height: 25vh;
  max-height: 450px;
  overflow-y: scroll;
}

#mapID {
  //height: 1000px;
}

.aheadeven {
  background:rgba(162, 222, 208, 0.4) !important;

}

.behindeven {
  background: rgba(214, 69, 65, 0.4) !important;
}
.aheadodd {
  background: rgba(162, 222, 208, 0.7) !important;

}

.behindodd {
  background: rgba(214, 69, 65, 0.7) !important;
}

.contractor-map-popup {
  min-width: 200px;
  text-align: center;

  label {
    font-size: 0.8em;
    display: block;
  }

  .job-ref {
    font-size: 1.3em;
    text-align: left;
    width: 100%;
    display: block;
    padding: 10px;
    margin-bottom: 6px;
  }

  i {
    display: block;
    float: right;
    margin-left: 15px;
  }
}

#contractor-dashboard-stats {
  a:hover {
    text-decoration: none;
  }
}

.jobs-filter-container {
  
  button {
    margin-top: 15px;
  }

  .chip {
    position: relative;

    &.job-toggle {
      padding-right: 40px;
    }

    &.aerial-job {
      i {background: rgba(#c62828, 1);}
    }

    &.matterport-job {
      i {background: rgba(#00695c, 1);}
    }

    &.photography-job {
      i {background: rgba(#1565c0, 1);}
    }

    &.video-job {
      i {background: rgba(#ef6c00, 1);}
    }

    i {
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 50%;
      width: 32px;
      height: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.9em;
    }

    label {
      color: #333;
      font-size: 1em;
      margin-bottom: 0;
    }
  }
}