.notifications-panel {

	.dismiss {
	    position: absolute;
	    top: 5px;
	    right: 0;
	    margin-right: 8px;
	    cursor: pointer;
	    border-radius: 5px;
	    background: $colour6;
	    color: #fff;
	    font-size: .7em;
	    height: 15px;
	    width: 15px;
	    text-align: center;
	    padding: 1px;
	    font-weight: 600;
	    font-family: "IBM Plex Sans", sans-serif;
	    text-transform: uppercase;
	}

	.notification-entry {
		padding: 5px 15px;
		margin-bottom: 5px;
		position: relative;
	}
}

.notification-panel {
	.notification-entry {

		a {
			color: $body-colour;
		}

		&.alert {
			color: #0c5460;
			background-color: #d1ecf1;
			border-color: #bee5eb;
		}

		&.action_required {
			color: #721c24;
    		background-color: #f8d7da;
    		border-color: #f5c6cb;
		}
	}
}