.weather-panel{

  .day-links{
    white-space: nowrap;
  }
  .date-title{
    display: inline-block;
    padding: 10px;
  }
  .hourly-weather-element{
    position: relative;
    display: inline-block;
    border: 1px solid #000000;

    .temperature{
      position: absolute;
      top: 0px;
      right: 5px

    }
    .time{
      position: absolute;
      top:0px;
      left: 5px;
    }
    .wind{
      position: absolute;
      bottom:0px;
      left: 5px;
    }
    .wind-direction{
      position:absolute;
      bottom: 0px;
      right: 5px;
    }

    .temperature::after{

      content: "\00b0\0043";
    }
  }


  .forecast-container{
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x:scroll;
    display:none;
  }

}
