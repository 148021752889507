.chat-windows {
    position: fixed;
    bottom: 0px;
    right: 300px;
    height: 350px;
    max-height: 350px;
    z-index: 9999;
    transition: 600ms;
    &.show-chat {
        bottom: 0px;
    }
    &.hide-chat {
        bottom: -350px;
    }
    .user-chat {
        min-width: 300px;
        min-height: 350px;
        background-color: $white;
        float: right;
        margin: 0 2px 0 0;
        &.mini-chat {
            min-width: 40px;
            min-height: 40px;
            bottom: 0px;
            top: 310px;
            position: relative;
            .chat-head .name,
            .chat-head i,
            .chat-footer,
            .chat-body {
                display: none;
            }
            .chat-head {
                img {
                    margin: 0;
                }
                .status {
                    left: 28px;
                    top: 10px;
                }
            }
        }
        .chat-head {
            height: 40px;
            padding: 10px;
            background-color: $primary-color;
            color: $white;
            position: relative;
            img {
                border-radius: 50%;
                height: 25px;
                width: 25px;
                vertical-align: middle;
                margin: -4px 8px 0 0;
                display: inline-block;
                cursor: pointer;
            }
            .status {
                border: 1px solid #fff;
                border-radius: 50%;
                position: absolute;
                height: 8px;
                width: 8px;
                left: 28px;
                top: 7px;
            }
            .online {
                @extend .green;
            }
            .busy {
                @extend .red;
            }
            .away {
                @extend .amber;
            }
            .offline {
                @extend .yellow;
            }
            i {
                font-size: 14px;
                float: right;
                color: $white;
                margin: 3px 0 0 5px;
                cursor: pointer;
            }
        }
        .chat-footer {
            padding: 0px 15px;
            border-top: 1px solid $border;
            .form-control {
                background-color: transparent;
                color: $body-text;
                font-size: 14px;
                border: 0px;
                width: 100%;
                height: 40px;
                &:focus,
                &:hover {
                    border: 0px;
                }
            }
        }
        .chat-body {
            height: 270px;
            padding: 15px 15px 0;
            background-color: $white;
            overflow: hidden;
            max-width: 300px;
            position: relative;
            .chat-list {
                li {
                    margin-top: 10px;
                    .chat-content {
                        padding-left: 0;
                    }
                    .chat-time {
                        margin: 5px 0 15px;
                    }
                }
            }
        }
    }
}

@media(max-width:767px) {
    .chat-windows {
        &.show-chat {
            right: 0;
        }
    }
}