.hitlist-item-menu{
    position: absolute;
    display:none;
    top:0px;
    z-index: 100;
    background-color: rgba(50,50,50,0.95);
    border: 1px solid #000;
    padding: 5px;
    width: 200px;
    color: #fff;


    .close{
        text-align: right;
        color: #fff;
        z-index: 1001;
        a{
            color:#FFF;
        }
    }
    .subfield{
        display:none;
        color: #000;
    }
}

#hitlist-container{
    .col-md-3{
        background-color: rgba(15,15,15,0.5);
        border: 2px solid #000;
    }
    .item-content{

        display:none;
    }
    .item-spacer{
        min-height: 100px;
    }
}
