@import 'notifications';
@import 'outstanding-invoices';
@import 'weather';
@import 'client-search';

.panel-pane {
	h2.pane-title {
		display: none;
	}

	.table-responsive .table {
		margin-bottom: 0;
	}
}

.panel-2col-bricks {
	.panel-col-first {
		width: 49%;
		margin-right: 1%;
		margin-left: 0;
	}

	.panel-col-last {
		width: 49%;
		margin-left: 1%;
		margin-right: 0;
	}
}

.panoptic-panel {
	//@extend .portal-section;
	position: relative;
}

.panel-body {
	//background: #ddd;
}

.panels-ipe-region, .panel {
	padding: 0;

	.table-responsive {
		border: 0;
		box-shadow: none !important;
	}

	.pane-title {
		text-transform: capitalize;
		padding-left: 15px;
		font-size: 1.5em;
		border-bottom: 1px solid #ced4da;
		padding-bottom: 15px;
	}
}

.pane-portal-client-notifications {
	h2.pane-title {
		display: none;
	}
}

.panel-default {
	background: none;
	border: none;
		
		>.panel-heading {
			background: none;
			padding: 0;
			font-weight: 600;
			border: none;
		}

		.panel-body {
			padding: 0;
		}
}