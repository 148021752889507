.right-side-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 25px;
    z-index: 10;
}

.right-sidebar {
    .sidenav {
        &.right-sidenav {
            position: fixed;
            @extend .white;
            width: 300px;
            top: 0;
            z-index: 999;
            padding-bottom: 10px;
            li {
                line-height: 20px;
            }
            .tabs {
                .tab {
                    a {
                        @extend .blue-text,
                        .text-accent-4;
                        opacity: 0.7;
                        font-size: 20px;
                        &:hover,
                        &.active {
                            opacity: 1;
                        }
                    }
                }
                .indicator {
                    @extend .blue,
                    .accent-4;
                }
            }
        }
    }
}

.theme-color {
    padding: 0px;
    margin: 0px;
    list-style: none;
    .theme-item {
        list-style: none;
        display: inline-block;
        margin-right: 5px;
        .theme-link {
            border-radius: 100%;
            width: 20px;
            height: 20px;
            display: block;
            padding: 0;
            &[data-logobg="skin1"],
            &[data-navbarbg="skin1"],
            &[data-sidebarbg="skin1"] {
                background: $skin1;
            }
            &[data-logobg="skin2"],
            &[data-navbarbg="skin2"],
            &[data-sidebarbg="skin2"] {
                background: $skin2;
            }
            &[data-logobg="skin3"],
            &[data-navbarbg="skin3"],
            &[data-sidebarbg="skin3"] {
                background: $skin3;
            }
            &[data-logobg="skin4"],
            &[data-navbarbg="skin4"],
            &[data-sidebarbg="skin4"] {
                background: $skin4;
            }
            &[data-logobg="skin5"],
            &[data-navbarbg="skin5"],
            &[data-sidebarbg="skin5"] {
                background: $skin5;
            }
            &[data-logobg="skin6"],
            &[data-navbarbg="skin6"],
            &[data-sidebarbg="skin6"] {
                background: $gray-200;
            }
        }
    }
}

.right-sidebar {
    .sidenav {
        &.right-sidenav {
            position: fixed;
            @extend .white;
            width: 300px;
            top: 0;
            z-index: 999;
            padding-bottom: 10px;
            li {
                line-height: 20px;
            }
            .tabs {
                .tab {
                    a {
                        @extend .blue-text, .text-accent-4;
                        opacity: 0.7;
                        font-size: 20px;
                        &:hover,
                        &.active {
                            opacity: 1;
                        }
                    }
                }
                .indicator {
                    @extend .blue, .accent-4;
                }
            }
            .mailbox {
                li {
                    .drop-title {
                        font-weight: $font-medium;
                        padding: 11px 20px 15px;
                        border-bottom: 1px solid $border;
                    }
                    a {
                        color: $body-text;
                    }
                }
                .message-center {
                    position: relative;
                    a {
                        border-bottom: 1px solid $border;
                        display: flex;
                        text-decoration: none;
                        padding: 9px 15px;
                        &:hover {
                            background: $light;
                        }
                        .user-img {
                            width: 50px;
                            position: relative;
                            display: inline-block;
                            margin: 0 0px 15px 0;
                            img {
                                width: 100%;
                                vertical-align: middle;
                            }
                            .profile-status {
                                border: 2px solid $white;
                                border-radius: 50%;
                                display: inline-block;
                                height: 10px;
                                left: 30px;
                                position: absolute;
                                top: 1px;
                                width: 10px;
                            }
                            .online {
                                @extend .green;
                            }
                            .busy {
                                @extend .red;
                            }
                            .away {
                                @extend .amber;
                            }
                            .offline {
                                @extend .yellow;
                            }
                        }
                        .mail-contnet {
                            width: 100%;
                            vertical-align: middle;
                            margin-left: 10px;
                            h5 {
                                margin: 5px 0px 0;
                                font-size: 16px;
                                color: $heading-text;
                            }
                            .mail-desc,
                            .time {
                                font-size: 12px;
                                display: block;
                                margin: 1px 0;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                color: $body-text;
                                white-space: nowrap;
                                width: 80%;
                            }
                        }
                        .btn-floating {
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
}