.message-box {
    ul li {
        .drop-title {
            font-weight: 500;
            padding: 11px 20px 15px;
            border-bottom: 1px solid $border;
        }
        .nav-link {
            border-top: 1px solid $border;
            padding-top: 15px;
        }
    }
    .message-widget {
        position: relative;
        a {
            border-bottom: 1px solid $border;
            display: block;
            text-decoration: none;
            padding: 12px 15px;
            &:hover {
                background: $gray-200;
            }
            &:last-child {
                border-bottom: 0px;
            }
            div {
                white-space: normal;
            }
            .user-img {
                width: 45px;
                position: relative;
                display: inline-block;
                margin: 0 10px 15px 0;
                vertical-align: middle;
                img {
                    width: 100%;
                }
                .profile-status {
                    border: 2px solid $white;
                    border-radius: 50%;
                    display: inline-block;
                    height: 10px;
                    left: 33px;
                    position: absolute;
                    top: -1px;
                    width: 10px;
                }
                .online {
                    background: $success;
                }
                .busy {
                    background: $danger;
                }
                .away {
                    background: $warning;
                }
                .offline {
                    background: $warning;
                }
            }
            .mail-contnet {
                display: inline-block;
                width: 70%;
                vertical-align: middle;
                h5 {
                    margin-bottom: 0;
                    font-size: 16px;
                }
                .mail-desc,
                .time {
                    
                    display: block;
                    margin: 1px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $muted; 
                    white-space: nowrap;
                }
                .time{
                    font-size: 12px;    
                } 
            }
        }
    }
}