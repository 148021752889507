.view-job-sheets-with-search {
  @extend .card;
  padding: 15px;
}

.ui-timepicker-table {
  td {
    a {
      width:auto !important;
    }
  }
}
