.feeds {
    margin: 0px;
    padding: 0px;
    .feed {
        list-style: none;
        padding: 12px 24px;
        display: block;
        &:hover {
            background: $gray-100;
        }
        >div {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            border-radius: 100%;
            color: $white;
            i {
                line-height: 40px;
            }
        }
        span {
            float: right;
            width: auto;
            font-size: 12px;
        }
    }
}