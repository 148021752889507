  .table-responsive {
    overflow-x: scroll;
  }

  #client-job-list {

    th {
      i {
        float: right;
        color: $colour1;

      }
    }

    td {
      vertical-align: top;
    }

    a {
      text-decoration: none;
      color: $colour1;
    }

    .job-title,
    .product-status {
      h5 {
        font-size: 2rem;
        color: $colour1;
      }

      p {
        font-weight: 600;

        span {
          font-weight: 600;
          font-size: 1.2rem;
          // margin-left: 10px;
          color: $colour1;
        }
      }
    }

    .icon {
      display: inline-block;
      font-size: 1.6rem;
      padding-right: 10px;
    }

    .job-dates {
      font-weight: 800;

      span {
        font-weight: 400;
      }
    }

    .dataTables_length {
      float: right;
    }

    .site-contact {
      i {
        min-width: 20px;
        margin-right: 5px;
        color: $colour1;
      }
    }

    .job-colour {
      max-width: 1px;
    }

    .product-status {
      i {
        min-width: 20px;
        margin-right: 10px;
      }
    }
  }

