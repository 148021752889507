.contact-job-history {
  max-height: 700px;
  overflow-y: scroll;

  a {
    text-decoration: none;
  }
  a :hover {
    text-decoration: none;
  }
  td {
    vertical-align: top;
  }
}

#add_note {
  a {
    margin-top: -45px;
  }
}

#add-note-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 3;
  padding: 200px;
  display: none;
  color: white;
  // align-items: center;
  text-align: center;
  div{
    max-width: 400px;
    display: inline-block;
  }
}

#add-note-close {
  float: right;
  top: 0px;
  color: white;
  font-weight: bolder;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

}

#add-payment-close {
  float: right;
  top: 0px;
  color: white;
  font-weight: bolder;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

}

#edit-field-note-text{
  .grippie{
    display: none !important;
  }
}
