.jobsheet-header {
  .job-navbar-toggle {
    width: 100%;
    text-align: center;
    background: $colour1;
    border-radius: 4px;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin-bottom: 5px;
  }
}

.job-sheet-tabs {
  margin-bottom: 15px;
}

.job-status {

  .fas {
    min-width: 20px;
  }

  span.status-label {
    min-width: 140px;
    font-weight: 600;
    display: inline-block;
  }
}

.map-btn {
  color: #fff !important;
}

.job-statuses {
  margin-top: 15px;
}

.client-logo {
  max-width: 100%;
}

.video-embed {
  padding: 56.25% 0 0 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.report-embed {
  padding: 75% 0 0 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.embed-code {
  .snippet {
    height: 100%;
    word-wrap: break-word !important;
  }
}

.clipboard-btn {
  float: right;
}

.user-avatar {
  img {
    @extend .avatar;
  }
}

.page-adminaddjobsheet {
  .tabbable.vertical-tabs {
    display: none;
  }
}



.overdue {
  border-radius: 15px;
  background: $colour5;
  display: inline-block;
  padding: 0 10px;
  color: #fff;
}

.completed {
  @extend .overdue;
  background: $colour4;
}

.company-logo {
  img {
    display: block;
    max-height: 100px;
    margin: 0 auto;
  }
}

.job-status {

  &.red {
    background-color: red;
  }

  &.orange {
    background-color: orange;
  }

  &.green {
    background-color: green;
  }

  &.lightblue {
    background-color: lightblue;
  }

  &.black {
    background-color: black;
  }
}

.view-job-sheets-with-search,
.view-client-job-sheets .view-view-leads-as-content-type {

  .job-status {
    text-align: center;
    color: #fff;
    font-size: 0.7em;
    max-width: 80px;
    padding: 3px;
    border-radius: 3px;
    box-shadow: 3px 3px 2px rgba(30, 30, 30, 0.1);

    &.red {
      background-color: red;
    }

    &.orange {
      background-color: orange;
    }

    &.green {
      background-color: green;
    }

    &.darkgreen {
      background-color: darkgreen;
    }

    &.lightblue {
      background-color: lightblue;
      color: $colour3;
    }

    &.black {
      background-color: black;
    }

    &.darkred {
      background-color: darkred;
    }

    &.blue {
      background-color: blue;
    }

    &.lightred {
      background-color: #eb7d7f;
    }

    &.beige {
      background-color: beige;
    }

    &.lightgreen {
      background-color: #90ee90;
      color: darkgreen;
    }

    &.darkblue {
      background-color: darkblue;
    }

    &.purple {
      background-color: purple;
    }

    &.darkpurple {
      background-color: #593869;
    }

    &.pink {
      background-color: pink;
    }

    &.cadetblue {
      background-color: cadetblue;
    }


    &.white {
      background-color: white;
      color: $colour3;
    }

    &.gray {
      background-color: gray;
    }

    &.lightgray {
      background-color: lightgray;
      color: $colour3;
    }

  }

  .views-field-title {
    span {
      display: block;
      text-align: center;
    }
  }

  .job-location {

    span {
      display: block;

      i {
        width: 17px;
      }
    }

    div {
      font-weight: 600;
      font-size: 1.2em;
      max-width: 280px;
      border-bottom: 1px solid $colour6;
      padding: 5px 0;
      margin-bottom: 5px;
    }
  }

  .btn-primary {
    width: 100%;
    transition: 0.3s ease background;
    padding: 3px;
    max-width: 80px;

    &:hover {
      color: #fff;
    }
  }
}



@media (min-width: 992px) {
  #jobsheet-nav-collapse {
    display: block;
    height: auto;

    .nav-pills {
      a {
        margin-right: 5px;
      }
    }
  }
}
