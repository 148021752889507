.contact-info-panel {
	padding: 15px;
}

.view-contact-notes-sub-view{
  max-height: 200px;
  overflow-y: scroll;
  .views-field-field-type::before {
    content: '\A';
    white-space: pre-wrap;
  }
}
