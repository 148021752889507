.icon-container {
	.icon-preview {
	    height: 120px;
		text-align: center;
	}
	i {
		font-size: 3em;
    	margin-bottom: 10px;
	}
	span {
		display: block;
	}
}
.dropdown-content{
    box-shadow:$shadow2;
    li > a > i{
        margin-right: 10px;
    }
}

p.header {
	font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $heading-text;
}

.waves-color-demo {
	.collection-item {
		height: 37px;
		line-height: 37px;
		box-sizing: content-box;
	}
}

.waves-color-demo {
	.btn:not(.waves-light), 
	.btn-large:not(.waves-light), 
	.btn-small:not(.waves-light) {
		background-color: $white;
	    color: $body-text;
	}
}

.dynamic-color .red,
.dynamic-color .pink,
.dynamic-color .purple,
.dynamic-color .deep-purple,
.dynamic-color .indigo,
.dynamic-color .blue,
.dynamic-color .light-blue,
.dynamic-color .cyan,
.dynamic-color .teal,
.dynamic-color .green,
.dynamic-color .light-green,
.dynamic-color .lime,
.dynamic-color .yellow,
.dynamic-color .amber,
.dynamic-color .orange,
.dynamic-color .deep-orange,
.dynamic-color .brown,
.dynamic-color .grey,
.dynamic-color .blue-grey,
.dynamic-color .black,
.dynamic-color .white,
.dynamic-color .transparent {
    height: 55px;
    width: 100%;
    padding: 0 15px;
    font-weight: 500;
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.dynamic-color .col {
    margin-bottom: 55px
}

.col.grid-example {
    border: 1px solid $border;
    margin: 7px 0;
    text-align: center;
    line-height: 50px;
    font-size: 28px;
    background-color: tomato;
    color: white;
    padding: 0
}

.col.grid-example span {
    font-weight: 100;
    line-height: 50px
}

#site-layout-example-left {
    background-color: #90a4ae;
    height: 300px
}

#site-layout-example-right {
    background-color: #26a69a;
    height: 300px
}

#site-layout-example-top {
    background-color: #E57373;
    height: 42px
}

.flat-text-header {
    height: 35px;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.15);
    display: block;
    margin: 27px auto
}

.flat-text {
    height: 25px;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.15);
    display: block;
    margin: 27px auto
}

.flat-text.small {
    width: 25%;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.15)
}

.flat-text.full-width {
    width: 100%
}

.browser-window {
    text-align: left;
    width: 100%;
    height: auto;
    display: inline-block;
    border-radius: 5px 5px 2px 2px;
    background-color: #fff;
    margin: 20px 0px;
    overflow: hidden
}

.browser-window .top-bar {
    height: 30px;
    border-radius: 5px 5px 0 0;
    border-top: thin solid #eaeae9;
    border-bottom: thin solid #dfdfde;
    background: -webkit-gradient(linear, left top, left bottom, from(#e7e7e6), to(#E2E2E1));
    background: linear-gradient(#e7e7e6, #E2E2E1)
}

.browser-window .circle {
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 1px
}

#close-circle {
    background-color: #FF5C5A
}

#minimize-circle {
    background-color: #FFBB50
}

#maximize-circle {
    background-color: #1BC656
}

.browser-window .circles {
    margin: 5px 12px
}

.browser-window .content {
    margin: 0;
    width: 100%;
    display: inline-block;
    border-radius: 0 0 5px 5px;
    background-color: #fafafa
}

.browser-window .row {
    margin: 0
}

.valign-demo {
    height: 300px;
    background-color: #ddd
}

.talign-demo {
    height: 100px;
    background-color: #ddd
}

.shadow-demo {
    background-color: #26a69a;
    width: 100px;
    height: 100px;
    margin: 20px auto
}

.select-wrapper .caret{
    fill:$gray-400;
}
.support-select{
    margin: 0px;
    .select-wrapper input{
        border-color:$gray-400;
        height: 28px;
    }
}
.ct-charts{
    position: relative;
}

.row{
    margin-bottom: 0px;
}
.row-minus{
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}
.popover-icon{
    a{
        margin-left: -15px;
        position: relative;
        &:first-child, &:last-child{
            margin-left: 0px;
        }
        &:hover{
            z-index: 2;
        }
    }
}
.no-table-border{
    
        tr{
            border: 0px;
        }
    
}
.table-with-spacing{
    tbody tr{
        border-top:10px solid $body-color;
        
    }
    td, th{
        padding: 12px;
    }
    
}
.table-responsive{
    overflow: auto;
    table{
        td{
            white-space: nowrap;
        }
    }
}

.collection2{
    border: 0px;
    a.collection-item{
    color:$body-text;
    padding: 10px 0px;
        &:not(.active):hover{
            background: $gray-100;
        }
    }
}

.btn, .btn-large, .btn-small, .btn-flat{
    text-transform: capitalize;
}