.panoptic {
  font-family: $panoptic-font;
  font-weight: 500;
  padding: 15px 0;
  line-height: 2em;
  font-size: 0.7em;
  color: #fff;
  text-align: center;

  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }

  .logo {
    max-width: 30px;
    display: inline-block;
    transition: 1s ease transform;
  }

  span {
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.5em;
  }

  &:hover {
    .logo {
      transform: rotate(180deg);
    }
  }
}

footer .dadjoke {
  display: inline-block;
  background: rgba(#222, 0.07);
  padding: 15px 30px;
  border-radius: 30px;
}
