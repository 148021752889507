.job-list-container{
  min-height: 25vh;
  overflow-y: scroll;
  padding: 5px 10px 5px 5px;
  max-height: 30vh;

  .item {

    td {
      padding: 0;
    }

    strong {
      font-weight: 600;
    }

    &:hover {
      cursor: pointer;
    }

  }
  // .job-item{

  // }
}

.contractors-map-admin, .contractors-map-user {
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
}

#mapID.contractors-map-user {
  height: 70vh;
}

.available-jobs-user, .registered-jobs-user {
  min-height: calc(25vh - 10px);
}

.jobs-to-contractors-header {
  padding: 15px 0;
}

.contractor-lower-container {
  padding: 15px 0;
}

.btn.register-interest {
    line-height: 1em;
    padding: 5px;
    background: #2dce89;
}

.job-map-listing {
  transition: 0.3s ease all;
  cursor: pointer;

  &.highlight {
    background: #c8e6c9;
    box-shadow: 0 0px 14px 0 rgba(60,66,87, 0.08), 0 0px 3px 0 rgba(0,0,0, 0.08);
  }
}

#all-contractors-container, #interested-contractors-container, #assigned-contractor-container {
  .btn-small {
    margin-bottom: 5px;
  }

  .item {
    padding: 10px 0;
    border-bottom: 1px solid #dcdcdc;
  }
}