.my-jobs-table-dashboard, .panel{
  padding: 10px !important;
  h2{

    text-align: center;

    a{
      font-size: 14px;
      float: right;
    }
  }

}

.lightred-text{
  @extend .red-text;
  @extend .text-lighten-2;

}
.lightred{
  @extend .red;
  @extend .lighten-2;
}

.darkred-text{
  @extend .red-text;
  @extend .text-darken-2;
}

.darkred{
  @extend .red;
  @extend .darken-2;
}

.lightgreen-text{
  @extend .green-text;
  @extend .text-lighten-2;
}

.lightgreen{
  @extend .green;
  @extend .lighten-2;
}

.darkgreen-text{
  @extend .green-text;
  @extend .text-darken-2;
}

.darkgreen{
  @extend .green;
  @extend .darken-2;
}
.darkblue{
  @extend .blue;
  @extend .darken-2;
}
.darkblue-text{
  @extend .blue-text;
  @extend .text-darken-2;
}

.lightblue{
  @extend .blue;
  @extend .lighten-2;
}
.lightblue-text{
  @extend .blue-text;
  @extend .text-lighten-2;
}

.darkpurple {
  @extend .purple;
  @extend .darken-2;
}

.darkpurple-text {
  @extend .purple-text;
  @extend .text-darken-2;
}
