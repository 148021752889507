.deliverables-gallery {
  .grid-item {
    padding: 1%;
  }

  .gallery-image-outer {
    box-shadow: 0 0 15px #ddd;
    border: 1px solid #ced4da;
    margin-bottom: 30px;
  }

  .gallery-title {
    font-size: 0.8em;
    text-align: center;
    font-weight: 600;
    padding: 5px;
  }

  .image-checkbox, .image-checked {

    &.checked {
      border: 4px $success solid;

      i {
        color: $success;

      }
      .checked{
        display: block;
      }
      .unchecked{
        display: none;
      }
    }

    i {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 2rem;
      color: rgba(30,30,30,0.3);
    }
    .checked{
      display:none;
    }
    .unchecked{
      display:block;
    }

  }
}
