.page-views-job-locations, .page-client-job-map, .my-job-map {

  .legend-link{
    font-size: 1rem;
    padding: 10px;
    color: $colour3;
  }
	h2.pane-title {
		display: none;
	}

	.field-group-htabs-wrapper {
		margin: 15px 0;
	}

	#sitecontainer {
		padding-top: 0;
		padding-bottom: 0;
	}

	.map-legend {

	}

	.portal-section {
		padding: 0;
		border: 0;

		&:hover {
			box-shadow: none;
			transform: none;
		}
	}

	#mapID {
		width: 100%;
		height: 80vh;
	}

	.pane-content {
		position: relative;
	}

	#navbar {
		margin-bottom: 0;
	}

	.main-container, #sitecontent {
		padding: 0;
		z-index: 100;
		overflow-x: hidden;
	}

	.panels-flexible-region-3-center {
		width: 100%;
	}

	.search-overlay {
		@extend .portal-section;
		padding: 15px;
		left: 15px;
		top: auto;
		bottom: 0;
		position: absolute;
		font-weight: 600;
	}

	.leaflet-control-layers {
		@extend .portal-section;

		label {
			span {
				text-transform: capitalize;
			}
		}
	}

	footer.footer {
		display: none;
	}

	.legend-item .icon .awesome-marker {
		transform: translateX(-50%) scale(1);
	}

	.legend-item .map-icon-title {
		font-size: 0.7em;
	}
}

.leaflet-popup-content-wrapper {
	.job-btn {
		a {
			@extend .btn;
			@extend .btn-primary;
			width: 100%;
			padding: 5px;
			text-align: center;
			font-size: 1.3em;
			margin-top: 10px;

			h4 {
				margin-bottom: 10px;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.legend-item {
	 text-align: center;
   vertical-align: top;
  .legend-pin {
    width: 34px;
    height: 34px;
    border-radius: 50% 50% 50% 0;
    background: #c30b82;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    display: inline-block;
  }
  .legend-title{
    font-size: 0.6em;
    font-weight: 600;
    display: inline;
  }
}

.marker-pin {
  width: 34px;
  height: 34px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

// to draw white circle
.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 5px 0 0 5px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

// to align icon
.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 12px auto;
  text-align: center;
}

.custom-div-icon i{
  margin: 14px 0 0 6px;
  font-size: 17px;
}
.dashboard-map{
  .my-job-map{
    #mapID{
      height: 50vh;
    }
  }
}
