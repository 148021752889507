/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

#main-wrapper[data-layout="horizontal"]{
    /****************************
    This is for the fixed header layout
    ***************************/
    &[data-header-position="fixed"] {
        .topbar {
            position: fixed;
            width: 100%;
        }
    }
    /****************************
    This is for the fixed sidebar layout
    ***************************/
    &[data-sidebar-position="fixed"] {
        .topbar {
            position: fixed;
            width: 100%;
        }
        .left-sidebar {
            position: fixed;   
        }
        .page-wrapper {
            padding-top: 64px;
        }
    }
    .container-fluid {
        padding: 0;
    }
    .page-titles {
        background: transparent;
        box-shadow: none;
        padding: 30px 10px 20px;
    }

    #slide-out .slide-wrap > .collapsible > li > a.has-arrow:after {
        display: none;
    }
}
/****************************
This is for the horizontal layout
***************************/

@media (min-width: 768px) {
    #main-wrapper[data-layout="horizontal"]{
        background: $body-color;
        .topbar {
            transition: 0s;
            z-index: 55;
            nav {
                padding-right: 0;
                .nav-wrapper > ul.left {
                    margin-left: 200px;
                }
            }
        }
        &[data-sidebar-position="fixed"],
        &[data-header-position="fixed"] {
            .left-sidebar {
                padding-top: 64px;
            }
        }
        /****************************
        This is for the Boxed horizontal layout
        ***************************/
        &[data-boxed-layout="boxed"]{
            .nav-wrapper, .side-wrap, .page-wrapper{
                max-width: $boxed-width;
                margin: 0 auto;
                position: relative;
            }
            
            .sidenav:not(.right-sidenav) {
                flex-wrap: wrap;
                >li > .has-arrow:after{
                    display: block;
                }
                li {
                    flex: 1 1 0;
                }    
            }
        }
        &[data-sidebar-position="fixed"]{
            .page-wrapper{
                padding-top: 128px;
            }
        }
        /****************************
        This is for the horizontal layout
        ***************************/
        .topbar nav .nav-wrapper .brand-logo {
            width:200px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            &:after {
                background: transparent;
            }
        }
        .topbar .nav-toggle,
        .topbar .sidebar-toggle {
            display: none;
        }
        .left-sidebar {
            width: 100%;
            height: auto;
            position: relative; 
            padding-top:0px;
            z-index: 45;
            top: 0; 
            transition: 0s;
        }
        
        .sidenav:not(.right-sidenav) {
            width: 100%;
            overflow-y: inherit;
            padding: 0;
            .collapsible {
                display: flex; 
                width: 100%;
                li {
                    position: relative;
                    width: auto;
                    a {
                        margin: 0;
                    }
                }
                >li {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    
                    >.has-arrow:after{
                        transform: rotate(-135deg) translate(0,-50%);
                    }
                    &:last-child > .collapsible-body:not(.second-level) {
                        right: 0px;
                        left: auto;
                    }
                    >.two-column + .collapsible-body{
                        width: 400px;
                        > ul > li{
                            float: $lft;
                            width: 50%;
                            vertical-align: top;
                        }
                    }
                    &:last-child {
                        border-right: none;
                        .collapsible .collapsible-body.second-level {
                            right:220px;
                            top:0px;
                            left: auto;
                            &+.has-arrow {
                                display: block;
                            }
                        }
                    }
                }
                >li .collapsible-body {
                    position: absolute;
                    left: 0px;
                    top: auto; 
                    width: 220px;
                    padding-bottom: 0px;
                    z-index: 100;
                    display: none!important;
                    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
                }
                >.mega-dropdown{
                    position: static;
                    .collapsible-body{
                        width: 100%;
                        > ul > li{
                            width: 25%;
                            float: $lft;
                        }
                    }
                }
                >li:hover{
                    background: rgba(0, 0, 0, 0.025);
                }
                >li:hover > .collapsible-body {
                    display: block!important;
                    &:after {
                        position: absolute;
                        content: "";
                        background:rgba(0, 0, 0, 0.025);
                        top: 0;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        z-index: -1;
                    }
                }
                li.multiple {
                    .collapsible {
                        display: block;
                        > li {
                            padding: 0;
                        }
                    }
                }
                >li .second-level{
                    left:220px;
                    top:0px;
                    
                }
                .small-cap {
                    display: none;  
                }
            }
        }
    }
}

@media (min-width:768px) and (max-width:1023px) {
    #main-wrapper[data-layout="horizontal"]{
        .left-sidebar .sidenav:not(.right-sidenav) ul li a > i.material-icons {
            display: none;
        }
        
        .sidenav:not(.right-sidenav) >li{
            flex: auto;
        }     
    }
    /****************************
    This is for the fixed sidebar layout
    ***************************/
    &[data-sidebar-position="fixed"]{
        .left-sidebar{
            overflow: auto;
        }
    }
}  