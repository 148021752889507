.portal-section {
	@extend .card;
	padding: 15px;
}

.page-header {
	.main-container {
		//margin-top: -20px;
	}

	.title-block, .title-block h1 {
		color: #fff;

		h1 {
			margin: 0;
		}
	}
}

// Drupal styles

#change-history {
	height: 280px;
	overflow-y: scroll;
}

.diff{
	border-radius:0px;
	td{
		padding:3px;
		background-color: #fff;
	}
	.diff-section-title{
		border-top: none;
		font-family:$body-font;
		font-size: 1em;
		font-weight: normal;
		border-radius:0px;
		.placeholder{
			color: $colour1;
			font-weight:bold;

		}

	}

	.diff-marker{
		font-size: 1.5rem;
		font-weight:bold;
		color: $colour1;
		text-align:right;
		vertical-align:middle;
		padding:0px;


	}
	.diff-context{
		vertical-align: middle;
	}
	.diff-deletedline{

		font-weight:bold;
		color:rgb(196, 52, 52);
		.diffchange{
			color:rgb(196, 52, 52);
		}
	}
	.diff-addedline{
		color:rgb(13, 121, 13);
		font-weight:bold;
		.diffchange{
			color:rgb(13, 121, 13);
		}
	}
}

// Remove padding top to fix on my local copy.
// @media (min-width: 1025px) {
// 	body {
// 		&.adminimal-menu {
// 			padding-top: 30px;
// 		}
// 	}
// }

.avatar {
	max-width: 60px;
	height: auto;
	box-shadow: 0 0 8px #bbb;
	border-radius: 50%;
}

.float-right {
	float: right;
	margin-left: 15px;
	margin-bottom: 15px;
}


.well {
	background: #fff;
	border: none;
}

.filter-wrapper {
	display: none;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.clip-corners {
	clip-path: polygon(90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%, 0 0);
	transform: rotate(0deg);
}

.flex {
	display: flex;
}

.v-center {
	align-self: center;
}

.wrapper {
	margin: 30px 0;
}

.whiteout {
	filter: brightness(0) invert(1);
}

.grayscale {
	filter: grayscale(100%);
}

.video-embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.client-grid {
	.client-logo {
		transition: 0.5s ease all;
		padding: 10%;

		.logo {
			max-height: 80px;
			max-width: 100%;
			margin: 0 auto;
			display: block;
			transition: 0.5s ease all;
		}
	}
}

.form-item-start-date-date, .form-item-end-date-date {
	.control-label {
		display: none;
	}
}

#mapID {
	width: 100%;
}

.select2-dropdown {
	border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	color: #000;
}

::selection {
  background: $colour1;
}
::-moz-selection {
  background: $colour1;
}

.img-blur-in {
    filter: blur(32px);
    transition: all ease .2s;
}

.field-group-htabs-wrapper {
	margin: 15px 0;
}

ol.breadcrumb {
	display: none;
}

.field-group-div {
	.description {
		margin-bottom: 15px;
	}
}

.ds-2col-fluid {

	.group-left {
		padding-right: 15px;
	}

	.group-right {
		padding-left: 15px;
	}
}

fieldset.form-wrapper {
	overflow: hidden;
}

// Table styles

.table-responsive {
	@extend .portal-section;
	padding: 0;

	table {
		margin-bottom: 0;
	}
}

.table {

	.btn {
		color: #fff;
	}

	a {
		color: $colour3;
		font-weight: 600;

		&:hover {
			text-decoration: none;
			color: $colour2;
		}
	}
}

.table > thead > tr > th {
	//border-bottom: 1px solid $colour3;
	background: rgba($colour3, 0.9);
	color: #fff;
	font-weight: 500;
	border: 0;

	a {
		color: #fff;
	}
}

.table > tr {
	border-left: 1px solid rgba($colour3, 0.3);
	border-right:  1px solid rgba($colour3, 0.3);
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
	border-top: 1px solid rgba($colour3, 0);
	padding: 5px;
}

.table-striped > tbody > tr:nth-of-type(even) {
	background: #fff;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	background: rgba($colour6, 0.1);
}

.views-field-field-location {
	span.country {
		display: none;
	}
}

.views-field-nothing, .views-field-nothing-1 {

	a {
		@extend .btn;
		padding: 5px;

		&:hover {
			text-decoration: none;
		}
	}
}

.gif-message {
	position: relative;

	.gif {
		width: 100%;
		border-radius: 15px;
	}

	.text {
		padding: 30px;
		color: #fff;
		position: absolute;
		top: 50%;
		width: 100%;
		text-align: center;
		transform: translateY(-50%);
		text-shadow: 0 0 5px #444;
	}
}

.LandbotPopup .LandbotFrame__frame {
	box-shadow: none !important;
}

.ui-draggable {
	z-index: 999;
}

body:not(.simplelogin):not(.page-user-reset) {
	.form-actions#edit-actions {
		position: fixed;
		right: 15px;
		top: 100px;
		max-width: 120px;
		z-index: 3000;

		.btn {
			display: block;
			width: 100%;
			margin: 0 0 5px 0;
		}
	}
}

@media (max-width: 990px) {

	.ds-2col-fluid {
		.group-left, .group-right {
			padding: 0;
			float: none;
			width: 100%;
		}
	}

}

@media (min-width: 991px) {


}
