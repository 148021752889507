.topbar nav .nav-wrapper .brand-logo .icon {
	padding-left: 20px;
}

.brand-logo {
	.icon, .text {
		img {
			max-height: 35px;
			width: auto;
			opacity: 0.9;
		}
	}
}

li.dadjoke {
	line-height: 1em;
	text-align: center;
	color: rgba(255,255,255,.5);
	padding: 10%;
	font-size: 0.7em;
}

.tabs {
	.indicator {
		background-color: $skin3;
	}

	.tab {
		a {
			text-decoration: none;
			text-transform: none;
			color: $skin1;

			&.active, &:hover {
				color: $skin3;
			}
		}
	}
}