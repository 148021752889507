.todo-list {
    .todo-item {
        border: 0px;
        margin-bottom: 0px;
        padding: 20px 15px 15px 0px;
        .custom-checkbox {
            width: 100%;
            .todo-label {
                font-weight: 400;
                width: 100%;
                .todo-desc:first-child {
                    width: 70%;
                    display: inline-block;
                    line-height: 25px;
                }
            }
        }
        &:last-child {
            border-bottom: 0px;
        }
        .assignedto {
            padding: 0px 0 0 30px;
            margin: 0px;
            .assignee {
                list-style: none;
                padding: 0px;
                display: inline-block;
                border: 0px;
                margin-right: 2px;
                .assignee-img {
                    width: 30px;
                    border-radius: 100%;
                }
            }
        }
        .item-date {
            padding-left: 30px;
            font-size: 12px;
            margin-top: 5px;
            display: inline-block;
        }
    }
}

.list-task .task-done {
    text-decoration: line-through;
}